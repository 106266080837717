<template>
  <div class="page-customers">
    <v-row justify="space-between" align="center">
      <span class="text-wrap display-1 ma-3">{{ $t('BCMGlobalNetwork') }}</span>
      <!-- <v-img
        contain
        max-width="32px"
        max-height="32px"
        src="../../assets/logo_cut.png"
      >
      </v-img> -->
      <v-spacer></v-spacer>
      <v-col align="end">
        <v-btn
          @click="refreshPage"
          :loading="loading"
          :disabled="loading"
          color="primary"
          :title="$t('refreshPage')"
        >
          <v-icon small>
            fas fa-redo
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-card>
      <v-alert
        outlined
        prominent
      >
        <template v-slot:prepend>
          <v-img
            contain
            max-width="64px"
            max-height="64px"
            src="../../assets/logo_cut.png"
            class="mr-3"
          >
          </v-img>
        </template>
        <v-row align="center">
          <v-col class="grow">
            Accédez à l'ensemble des informations concernant le réseau CMT Global Network
          </v-col>
          <v-col
            class="d-flex flex-row justify-space-around"
            align="end"
          >
            <v-btn
              color="primary"
              outlined
              :to="{name: 'CustomerInformation', params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: 'BCM' }}"
              :title="$t('information')"
              class="text-none mr-2"
            >
              {{ $t('information') }}
              <v-icon small class="ml-2">
                fas fa-info-circle
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              outlined
              :to="{name: 'CustomerContacts', params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: 'BCM' }}"
              :title="$t('contacts')"
              class="text-none mr-2"
            >
              {{ $t('contacts') }}
              <v-icon small class="ml-2">
                fas fa-address-book
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              :to="{name: 'CustomerQuotes', params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: 'BCM' }}"
              :title="$t('quotes')"
              class="text-none mr-2"
            >
              {{ $t('quotes') }}
              <v-icon small class="ml-2">
                fas fa-file-signature
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              :to="{name: 'CustomerOrders', params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: 'BCM' }}"
              :title="$t('orders')"
              class="text-none mr-2"
            >
              {{ $t('orders') }}
              <v-icon small class="ml-2">
                fa-box-open
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              :to="{name: 'CustomerParts', params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: 'BCM' }}"
              :title="$t('parts')"
              class="text-none"
            >
              {{ $t('parts') }}
              <v-icon small class="ml-2">
                fas fa-cubes
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-alert>
    </v-card>
    <v-row
      justify="start"
      align="center"
      align-content="stretch"
      class="mt-2"
    >
      <span class="text-wrap display-1 ma-3">{{ $t('myCustomers') }}</span>
      <v-icon
        color="privateSupplier"
        size="20px"
        class="mx-auto"
      >
        fas fa-warehouse
      </v-icon>
      <v-spacer></v-spacer>
    </v-row>
    <v-divider class="mt-2 mb-4"></v-divider>
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          :label="$t('Search')"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <SupplierDataTableWarehouses
          :items="privateWarehouses"
          :search="search"
          :to="'Customer'"
        >
        </SupplierDataTableWarehouses>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { ApiErrorParser, DownloadSupplierPartImage } from '@cloudmanufacturingtechnologies/portal-components';

import SupplierDataTableWarehouses from '../../components/supplierDataTableWarehouses/SupplierDataTableWarehouses.vue';

const i18nData = require('./pageCustomers.i18n');

export default {
  name: 'PageCustomers',
  components: { SupplierDataTableWarehouses },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      headers: [],
      loading: false,
      privateWarehouses: [],
      search: '',
    };
  },
  created() {
    this.getSupplierPrivateWarehouses();
  },
  mounted() {
  },
  methods: {
    getSupplierPrivateWarehouses() {
      this.loading = true;
      /**
       * GET ALL PARTS (return part that has been homologated by the Supplier)
       */
      this.$apiInstance
        .getSupplierPrivateWarehouses(this.$route.params.supplierUUID)
        .then(
          (data) => {
            this.privateWarehouses = data;
          },
          (error) => {
            /**
             * ERROR GET ALL QUOTES
             */
            ApiErrorParser.parse(error);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    refreshPage() {
      this.loading = true;
      this.getSupplierPrivateWarehouses();
    },
    goToPageItem(item) {
      this.$router.push({name: 'BcmPartOverview', params: { supplierUUID: `${this.$route.params.supplierUUID}`, partUUID: `${item._id}`}});
    },
  },
};
</script>
