var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-customers"
  }, [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('BCMGlobalNetwork')))]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "loading": _vm.loading,
      "disabled": _vm.loading,
      "color": "primary",
      "title": _vm.$t('refreshPage')
    },
    on: {
      "click": _vm.refreshPage
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-redo ")])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-alert', {
    attrs: {
      "outlined": "",
      "prominent": ""
    },
    scopedSlots: _vm._u([{
      key: "prepend",
      fn: function fn() {
        return [_c('v-img', {
          staticClass: "mr-3",
          attrs: {
            "contain": "",
            "max-width": "64px",
            "max-height": "64px",
            "src": require("../../assets/logo_cut.png")
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "grow"
  }, [_vm._v(" Accédez à l'ensemble des informations concernant le réseau CMT Global Network ")]), _c('v-col', {
    staticClass: "d-flex flex-row justify-space-around",
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "primary",
      "outlined": "",
      "to": {
        name: 'CustomerInformation',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: 'BCM'
        }
      },
      "title": _vm.$t('information')
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('information')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-info-circle ")])], 1), _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "primary",
      "outlined": "",
      "to": {
        name: 'CustomerContacts',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: 'BCM'
        }
      },
      "title": _vm.$t('contacts')
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('contacts')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-address-book ")])], 1), _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "primary",
      "to": {
        name: 'CustomerQuotes',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: 'BCM'
        }
      },
      "title": _vm.$t('quotes')
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('quotes')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-file-signature ")])], 1), _c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "primary",
      "to": {
        name: 'CustomerOrders',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: 'BCM'
        }
      },
      "title": _vm.$t('orders')
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('orders')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa-box-open ")])], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "primary",
      "to": {
        name: 'CustomerParts',
        params: {
          supplierUUID: "".concat(_vm.$route.params.supplierUUID),
          customerUUID: 'BCM'
        }
      },
      "title": _vm.$t('parts')
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('parts')) + " "), _c('v-icon', {
    staticClass: "ml-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-cubes ")])], 1)], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "justify": "start",
      "align": "center",
      "align-content": "stretch"
    }
  }, [_c('span', {
    staticClass: "text-wrap display-1 ma-3"
  }, [_vm._v(_vm._s(_vm.$t('myCustomers')))]), _c('v-icon', {
    staticClass: "mx-auto",
    attrs: {
      "color": "privateSupplier",
      "size": "20px"
    }
  }, [_vm._v(" fas fa-warehouse ")]), _c('v-spacer')], 1), _c('v-divider', {
    staticClass: "mt-2 mb-4"
  }), _c('v-card', [_c('v-card-title', [_c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": _vm.$t('Search'),
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('v-card-text', [_c('SupplierDataTableWarehouses', {
    attrs: {
      "items": _vm.privateWarehouses,
      "search": _vm.search,
      "to": 'Customer'
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }