<template>
  <v-data-table
    class="component-supplierDataTableWarehouses"
    :headers="computedHeaders"
    :items="items || []"
    :search="search"
    :custom-filter="customFilter"
    sort-by="created"
    :sort-desc="true"
    :footer-props="{
      itemsPerPageOptions: [15, 50, 100, -1]
    }"
  >
    <template v-slot:item="{ item }">
      <tr
        :ref="`row_${item._id}`"
        @click="goToPageItem(item)"
      >
        <td>
          <router-link
            :to="{name: 'Part', params: { supplierUUID: `${$route.params.supplierUUID}`, partUUID: `${item._id}`}}"
            class="text-decoration-none d-block text-truncate"
            event
          >
            <div class="d-flex align-center justify-start">
              <span class="ml-4">
                {{ item.name }}
              </span>
            </div>
          </router-link>
        </td>
        <td>
          <router-link
            :to="{name: 'Part', params: { supplierUUID: `${$route.params.supplierUUID}`, partUUID: `${item._id}`}}"
            class="text-decoration-none d-block text-truncate"
            event
          >
            <div class="d-flex align-center justify-start">
              <span class="ml-4">
                {{ item.address.company }}, {{ item.address.streetAddress }}, {{ item.address.zipCode }} {{ item.address.city }}, {{ item.address.state }} {{ item.address.country }}
              </span>
            </div>
          </router-link>
        </td>
        <td>
          <router-link
            :to="{name: 'Part', params: { supplierUUID: `${$route.params.supplierUUID}`, partUUID: `${item._id}`}}"
            class="text-decoration-none"
            event
          >
            {{ $d(new Date(item.created), "long", $userLocale) }}
          </router-link>
        </td>
        <td class="text-center">
          <v-btn
            color="primary"
            :to="{name: to, params: { supplierUUID: `${$route.params.supplierUUID}`, customerUUID: `${item._id}`}}"
            :title="$t('view')"
          >
            <v-icon small>
              fas fa-eye
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
const i18nData = require('./supplierDataTableWarehouses.i18n');

import { CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';

export default {
  name: 'SupplierDataTableWarehouses',
  props: {
    /**
     * Parts
     */
    items: {
      type: Array,
      default: null
    },
    /**
     * Destination page when clicking on a row
     */
    to: {
      type: String,
      required: true
    },
    search: {
      type: String,
      default: ''
    },
  },
  components: {
  },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  watch: {
    items: {
      handler: function() {
      }
    }
  },
  data() {
    return {
      headers: [],
      imageTooltipValues: {},
    };
  },
  created() {
    this.headers = [
      { 
        text: this.$t('Name'),
        value: 'name',
        show: true
      },
      { 
        text: this.$t('Address'),
        value: 'address',
        sortable: false,
        show: true
      },
      { 
        text: this.$t('Date'),
        value: 'created',
        show: true,
      },
      {
        text: this.$t('Action'),
        value: 'action',
        align: 'center',
        sortable: false,
        show: true
      },
    ];
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(h => {return h.show;});
    }
  },
  methods: {
    customFilter(item, search, filter) {
      if (!search) return true;
      if (item?.address?.company.includes(search)) return true;
      if (item?.address?.streetAddress.includes(search)) return true;
      if (item?.address?.zipCode.includes(search)) return true;
      if (item?.address?.city.includes(search)) return true;
      if (item?.address?.state.includes(search)) return true;
      if (item?.address?.country.includes(search)) return true;
      if (item?.address?.VATnumber.includes(search)) return true;
      if (item?.address?.firstname.includes(search)) return true;
      if (item?.address?.lastname.includes(search)) return true;
      if (item?.address?.phoneNumber.includes(search)) return true;
      if(!isNaN(Date.parse(item))) {
        if(this.$d(new Date(item), 'long', this.$userLocale).includes(search)) return true;
      }
      return CustomFilterForSearchDatatable.customFilter(item, search, this);
    },
    customSort(items, index, isDesc) {
      return items;
    },
    goToPageItem(item) {
      this.$router.push({name: this.to, params: { supplierUUID: `${this.$route.params.supplierUUID}`, customerUUID: `${item._id}`}});
    },
  },
};
</script>
