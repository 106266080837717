var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "component-supplierDataTableWarehouses",
    attrs: {
      "headers": _vm.computedHeaders,
      "items": _vm.items || [],
      "search": _vm.search,
      "custom-filter": _vm.customFilter,
      "sort-by": "created",
      "sort-desc": true,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('tr', {
          ref: "row_".concat(item._id),
          on: {
            "click": function click($event) {
              return _vm.goToPageItem(item);
            }
          }
        }, [_c('td', [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'Part',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-start"
        }, [_c('span', {
          staticClass: "ml-4"
        }, [_vm._v(" " + _vm._s(item.name) + " ")])])])], 1), _c('td', [_c('router-link', {
          staticClass: "text-decoration-none d-block text-truncate",
          attrs: {
            "to": {
              name: 'Part',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [_c('div', {
          staticClass: "d-flex align-center justify-start"
        }, [_c('span', {
          staticClass: "ml-4"
        }, [_vm._v(" " + _vm._s(item.address.company) + ", " + _vm._s(item.address.streetAddress) + ", " + _vm._s(item.address.zipCode) + " " + _vm._s(item.address.city) + ", " + _vm._s(item.address.state) + " " + _vm._s(item.address.country) + " ")])])])], 1), _c('td', [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'Part',
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                partUUID: "".concat(item._id)
              }
            },
            "event": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$d(new Date(item.created), "long", _vm.$userLocale)) + " ")])], 1), _c('td', {
          staticClass: "text-center"
        }, [_c('v-btn', {
          attrs: {
            "color": "primary",
            "to": {
              name: _vm.to,
              params: {
                supplierUUID: "".concat(_vm.$route.params.supplierUUID),
                customerUUID: "".concat(item._id)
              }
            },
            "title": _vm.$t('view')
          }
        }, [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" fas fa-eye ")])], 1)], 1)])];
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }